(function () {
    'use strict';

    angular
        .module('smartermail')
        .controller('aliasSummaryController', aliasSummaryController);

    function aliasSummaryController($scope, $mdDialog, name, targets, includeAllDomainUsers, userNames) {
        $scope.name = name;
        $scope.targets = targets;
        $scope.userNames = userNames;
        // lets combine the usernames and the targets into a dictionary
        if (targets != null) { 
        $scope.aliasTargets = {};
        for (var i = 0; i < targets.length; i++) {
            $scope.aliasTargets[targets[i]] = userNames[i];
            }
        }
        $scope.includeAllDomainUsers = includeAllDomainUsers;

        $scope.cancel = function () {
            $mdDialog.cancel();
        };
    };
})();