(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("xmppService", xmppService);

	function xmppService($rootScope,
		$filter,
		$timeout,
		$log,
		$sanitize,
		coreData,
		coreDataContacts,
		coreDataSettings,
		browserNotifications,
		preferencesStorage,
		tokenRefreshService,
		userDataService,
		claimsService,
		authStorage,
		signalrHubManager,
		stropheConnectionService) {

		// TODO: Get pics from api/v1/contacts/domain
		// Data
		var vm = this;

		vm.boshUrl = null;
		vm.host = null;
		vm.username = userDataService.user.username;
		vm.email = userDataService.user.emailAddress;
		vm.historyLoaded = false;
		//vm.status = "connecting";
		vm.contactCategories = [];
		vm.logTraffic = false;
		var idCounter = 1;
		vm.firstConnection = false;
		vm.states = stropheConnectionService.parameters.states;
		$rootScope.$on('signalRHubManagerReconnected', reconnect);
		$rootScope.$on('xmpp.reconnect-needed', reconnect);
		function reconnect() {
			if (!stropheConnectionService.connected) {
				vm.init();
			}
		}
		vm.parameters = {
			get connected() {
				return stropheConnectionService.parameters.connected;
			},
			get status() {
				return stropheConnectionService.parameters.status;
			},

			get prevStatus() {
				var value = preferencesStorage.getSortingFilteringParam("chat", "prevStatus");
				if (value === undefined) {
					value = "available"; preferencesStorage.setSortingFilteringParam("chat", "prevStatus", value);
				}
				return value;
			},
			set prevStatus(value) { preferencesStorage.setSortingFilteringParam("chat", "prevStatus", value); },

			get unreadCounts() {
				var value = preferencesStorage.getSortingFilteringParam("chat", "unreadCounts");
				if (value === undefined) {
					value = {}; preferencesStorage.setSortingFilteringParam("chat", "unreadCounts", value);
				}
				return value;
			},
			set unreadCounts(value) { preferencesStorage.setSortingFilteringParam("chat", "unreadCounts", value); },

			get isPopup() {
				return window.opener && !window.opener.closed;
			},


			get notify() {
				var value = preferencesStorage.getSortingFilteringParam("chat", "notify");
				if (value === undefined)
					value = coreDataSettings.userSettings.notifyOnChatMessages;
				return value;
			},

		};


		// Functions
		vm.close = close;
		vm.markRead = markRead;
		vm.sendMessage = sendMessage;
		vm.notifyComposing = notifyComposing;
		vm.setStatus = setStatus;
		vm.stopComposing = stopComposing;
		vm.findContact = findContact;
		vm.init = init;
		vm.getUserStatus = getUserStatus;
		// Startup
		if (!window.Strophe) {
			throw new Error('Please make sure to include Strophe library. http://strophe.im/strophejs/');
		}

		activate();

		function activate() {


		}
		function init() {
			stropheConnectionService.connect(authStorage.getToken())
				.then(onConnected, () => { });
		}
		function cleanUnreadCounts() {
			var counts = vm.parameters.unreadCounts;
			vm.parameters.unreadCounts =
				Object.keys(counts).reduce((acc, key) => {
					if (counts[key].unread > 0) {
						acc[key] = counts[key];
					}
					return acc;
				}, {});
		}

		function getUserStatus(email) {
			const contact = findContact(email);
			return contact
				? contact.status
				: null;
		}

		function checkSelectContact() {
			var jid = preferencesStorage.getSortingFilteringParam("chat", "selectContact");
			if (jid) {
				$rootScope.$broadcast("xmpp.select-user", { jid: jid });
				preferencesStorage.setSortingFilteringParam("chat", "selectContact", "");
				return;
			}

			var selectRecent = preferencesStorage.getSortingFilteringParam("chat", "selectRecentUnread");
			if (!selectRecent) return;
			preferencesStorage.setSortingFilteringParam("chat", "selectRecentUnread", undefined);

			var counts = vm.parameters.unreadCounts;
			var mostRecent = { unread: 0, time: moment(0) };
			for (var key in counts) {
				var contact = counts[key];
				if (contact.unread <= 0) continue;

				contact.time = moment(contact.time);
				if (contact.time > mostRecent.time) {
					mostRecent = contact;
					mostRecent.jid = key;
				}
			}

			if (mostRecent.jid)
				$rootScope.$broadcast("xmpp.select-user", { jid: mostRecent.jid });
		}

		// Implementation


		function markRead(contact) {
			const signalChange = contact && contact.unreadCount;
			contact.unreadCount = 0;
			recalculateUnread();
			if (signalChange) signalrHubManager.connection.invoke("chatMarkUserAsRead", authStorage.getRefreshToken(), contact.jid);
		}

		var isTyping = false;
		function sendMessage(toJID, message) {
			if (!message || !stropheConnectionService.parameters.connected) { return; }

			if (isTyping) {
				stropheConnectionService.parameters.connection.Messaging.active(toJID);
				isTyping = false;
			}

			stropheConnectionService.parameters.connection.send($msg({ to: toJID, type: 'chat' }).c('body').t(message).tree());
			var foundItem = findContact(toJID);
			if (!foundItem.conversation)
				foundItem.conversation = [];

			message = $('<div/>').text(message).html();
			var conversationItem = { text: linkify(message, true), jid: toJID, name: userDataService.user.displayName || userDataService.user.username, pic: null, isMe: true, dt: new Date() };
			foundItem.conversation.push(conversationItem);
			foundItem.dt = new Date();

			$rootScope.$broadcast('xmpp.conversation-changed', { jid: toJID, conversation: foundItem.conversation });
		}

		function notifyComposing(toJID) {
			if (!stropheConnectionService.parameters.connected)
				return;
			if (isTyping)
				return;

			isTyping = true;
			stropheConnectionService.parameters.connection.Messaging.composing(toJID);
		}

		function stopComposing(toJID) {
			if (!stropheConnectionService.parameters.connected)
				return;
			if (!isTyping)
				return;
			isTyping = false;
			stropheConnectionService.parameters.connection.Messaging.paused(toJID);
		}

		function setStatus(newStatus) {
			stropheConnectionService.parameters.status = newStatus;
			$rootScope.$broadcast('xmpp.property-changed', { status: newStatus });
		}

		function onConnected() {
			stropheConnectionService.removeHandlers();

			//cleanUnreadCounts();
			var xmppRequest = $iq({ type: "get", id: "_roster_" + (idCounter++) }).c("query", { xmlns: Strophe.NS.ROSTER });
			stropheConnectionService.parameters.connection.sendIQ(xmppRequest, onRoster);
			stropheConnectionService.parameters.connection.messageCarbons.enable(onMessageCarbon);
			setStatus(vm.parameters.status);
			//stropheConnectionService.parameters.connection.send($pres({ to: userDataService.user.emailAddress.toLowerCase(), "type": "subscribe" }));

		}
		
		function onMessageCarbon(carbon) {
			var body = $(carbon.innerMessage).children("body").text() || "";
			if (!body)
				return;

			if (carbon.direction === 'sent') {
				var bareTo = Strophe.getBareJidFromJid(carbon.to);
				var foundItem = findContact(bareTo);
				if (foundItem && carbon.type == "chat") {
					foundItem.conversation = foundItem.conversation || [];
					body = $('<div/>').text(body).html();
					var conversationItem = { text: linkify(body, true), jid: bareTo, name: userDataService.user.displayName || userDataService.user.username, pic: null, isMe: true, dt: new Date() };
					foundItem.conversation.push(conversationItem);
					$rootScope.$broadcast('xmpp.conversation-changed', { jid: bareTo, conversation: foundItem.conversation });
				}
			}
			else {
				onMessage(carbon.innerMessage);
			}
		}

		async function onRoster(roster) {
			//I'm using a try catch here because i've seen errors happen here and chrome doesn't catch and throw them itself.
			try {
				if (!vm.contactCategories)
					vm.contactCategories = [];

				stropheConnectionService.parameters.connection.addHandler(onPresence, null, 'presence');
				for (let item of $(roster).find("item")) {
					let contact = await contactFromIqItem(item);
					addRosterContact(contact);
					addContact(contact.jid, contact.name);

				}


				try {
					const promises = [ queryMessageHistoryAsync(2)];
					const chatGroups = vm.contactCategories.find(cat => cat.name.endsWith("Aliases"));
					if (chatGroups && chatGroups.contacts) {
						for (const groupContact of chatGroups.contacts) {
							promises.push(queryDirectMessageHistoryAsync(groupContact, 2));
						}
					}
					await Promise.all(promises);
					vm.historyLoaded = true;
					$rootScope.$broadcast('xmpp.query-history-complete');
				} catch (e) {
					console.error("failed to get message history: ", e);
				}
				$rootScope.$broadcast('xmpp.contacts-changed', { contactCategories: vm.contactCategories });

				stropheConnectionService.parameters.connection.addHandler(onRosterChanged, Strophe.NS.ROSTER, 'iq', 'set');
				stropheConnectionService.parameters.connection.addHandler(onIQ, null, 'iq');
				stropheConnectionService.parameters.connection.addHandler(onMessage, null, 'message', "chat");
				stropheConnectionService.parameters.connection.addHandler(onGroupMessage, null, 'message', "groupchat");
			} catch (exception) {
				$log.error(exception); //Chrome doesn't seem to like the error I get here so the throw statement doesn't work on its on.
				throw new Error(exception);
			} finally {
				vm.firstConnection = false;
			}
			//checkRegisteredStatuses();
		}

		async function onRosterChanged(roster, iq) {
			for (let item of $(roster).find("item")) {
				var contact = await contactFromIqItem(item);
				if (contact.subscription == "remove")
					removeRosterContact(contact);
				else
					addRosterContact(contact);
			}
			$rootScope.$broadcast('xmpp.contacts-changed', { contactCategories: vm.contactCategories });
			recalculateUnread();
			//checkRegisteredStatuses();
			return true;
		}

		vm.joinRoom = joinRoom;
		function joinRoom(roomJid, userAlias, onRoomJoined) {
			vm.onRoomJoined = onRoomJoined;
			vm.roomJoining = roomJid + "/" + userAlias;
			stropheConnectionService.parameters.connection.send($pres({ to: roomJid + "/" + userAlias }).c('x', { xmlns: "http://jabber.org/protocol/muc" }));
		}

		function onPresence(presence) {

			try {
				setPresence(presence);
			} catch (e) {
				console.error("Error in setPresence:", presence, e);
			}
			return true;
		}
		function getStatusFromShow(show, ptype) {
			if (ptype === 'error') {
				return 'error';
			}

			var status;
			if (ptype === 'unavailable') {
				status = 'offline';
			} else {
				if (show === "" || show === "chat") {
					status = 'available';
				} else if (show === 'dnd') {
					status = 'dnd';
				} else {
					status = 'away';
				}
			}

			return status;
		}
		function setPresence(presence) {
			const ptype = $(presence).attr('type');
			const from = $(presence).attr('from');
			const bareJid = Strophe.getBareJidFromJid(from);
			const resourceID = Strophe.getResourceFromJid(from);
			const show = $(presence).find("show").text();
			if (ptype === 'error')
				return;
			const status = getStatusFromShow(show, ptype);
			if (!status) return;
			if (bareJid.toLowerCase() === userDataService.user.emailAddress.toLowerCase()) {
				if (resourceID) {

					preferencesStorage.setSortingFilteringParam("chat", "status", status);
					$rootScope.$broadcast('xmpp.property-changed', { status: status });
				}
				return;
			}

			if (vm.roomJoining === from && vm.onRoomJoined) {
				if (ptype === 'error')
					vm.onRoomJoined(false, from);
				var isRoomJoinRequestResponse = $(presence).find("status[code='110']").length > 0;
				if (isRoomJoinRequestResponse)
					vm.onRoomJoined(true, from);
			}


			var foundItem = findContact(bareJid);
			if (foundItem && foundItem.status !== "room" && foundItem.status !== status) {
				foundItem.status = status;

				$rootScope.$broadcast('xmpp.contacts-changed', { contactCategories: vm.contactCategories, loaded: true });
			}
		}

		function onIQ(iq) {
			/*
			<body xmlns='http://jabber.org/protocol/httpbind'><iq xmlns='jabber:client' type='set' from='xmpptest@familykid.com' to='xmpptest@familykid.com/Client_2f4a57'><query xmlns='jabber:iq:roster'><item jid='xmpptestgroup@familykid.com' subscription='both' name='XMPPTestGroup'><group>familykid.com - Aliases</group></item></query></iq></body>
			<body xmlns='http://jabber.org/protocol/httpbind'><presence xmlns='jabber:client' from='xmpptestgroup@familykid.com' to='xmpptest@familykid.com/Client_2f4a57'/><presence xmlns='jabber:client' from='xmpptest@familykid.com/Client_2f4a57' to='xmpptestgroup@familykid.com'/><presence xmlns='jabber:client' from='xmpptestgroup@familykid.com'/></body>
			*/
			return true;
		}
		async function queryDirectMessageHistoryAsync(contact, months) {
			var today = new Date();
			var oneYearAgo = new Date();
			oneYearAgo.setMonth(today.getMonth() - months);
			contact.archived_conversation = contact.archived_conversation || [];
			var startDate = oneYearAgo.toISOString().split('.')[0] + 'Z';
			var endDate = today.toISOString().split('.')[0] + 'Z';
			const queryId = `${contact.jid}-${new Date().getTime()}`;
			const handleMessage = async (message) => {
				try {
					const msgQueryId = $(message).find('result[xmlns="urn:xmpp:mam:2"]').attr('queryid');
					if (msgQueryId !== queryId)
						return false;
					const delayIndicator = $(message).find("forwarded delay");
					const dt = delayIndicator.length ? new Date(delayIndicator.attr("stamp")) : new Date();
					const msg = $(message).find("forwarded message");
					const unseen = $(msg).find("unseen").length;
					const from = Strophe.getBareJidFromJid(msg.attr("from"));
					let body = msg.find("body").text();

					const isMe = from === userDataService.user.emailAddress;
					const name = isMe ? userDataService.user.displayName || userDataService.user.username :
						contact.name;
					if (!isMe && unseen) {
						contact.unreadCount++;
					}
					body = $('<div/>').text(body).html();
					const m = {
						text: linkify(body, true),
						jid: from,
						name: name,
						pic: isMe ? null : contact.pic,
						isMe: isMe,
						dt: dt
					};
					if (!contact.dt || dt > contact.dt) {
						contact.dt = dt;
					}
					contact.archived_conversation.push(m);

				} catch (err) {
					$log.error(err);
				}
				return true;
			};
			const handleRef = stropheConnectionService.parameters.connection.addHandler(handleMessage, null, 'message', "group");
			const handleComplete = async (response) => {
				stropheConnectionService.parameters.connection.deleteHandler(handleRef);
				$rootScope.$broadcast('xmpp.conversation-changed', { jid: contact.jid, archived_conversation: contact.archived_conversation });
				return true;
			};

			return new Promise((resolve, reject) => {
				stropheConnectionService.parameters.connection.mam.query(vm.username, {
					"start": startDate,
					"end": endDate,
					'with': contact.jid,
					'max': 50,
					'queryid': queryId,
					onMessage: handleMessage,
					onComplete: (response) => resolve(handleComplete(response)),
					onError: (error) => {
						reject(error);
						stropheConnectionService.parameters.connection.deleteHandler(handleRef);
					}
				});
			});
		}
		async function queryMessageHistoryAsync(months) {

			var today = new Date();
			var oneYearAgo = new Date();
			oneYearAgo.setMonth(today.getMonth() - months);

			// Format dates to ISO string without milliseconds
			var startDate = oneYearAgo.toISOString().split('.')[0] + 'Z';
			var endDate = today.toISOString().split('.')[0] + 'Z';

			const queryId = `dms-${new Date().getTime()}`;

			const handleMessage = async (message) => {
				try {
					const msgQueryId = $(message).find('result[xmlns="urn:xmpp:mam:2"]').attr('queryid');
					if (msgQueryId !== queryId)
						return false;
					const delayIndicator = $(message).find("forwarded delay");
					const dt = delayIndicator.length ? new Date(delayIndicator.attr("stamp")) : new Date();
					const msg = $(message).find("forwarded message");
					const from = Strophe.getBareJidFromJid(msg.attr("from"));
					const to = Strophe.getBareJidFromJid(msg.attr("to"));
					const unseen = $(message).find("unseen").length;
					const type = msg.attr("type");
					let body = msg.find("body").text();
					const isMe = from === userDataService.user.emailAddress;
					const contactFrom = vm.findContact(to);
					if (!contactFrom)
						return true;
					const name = isMe ? userDataService.user.displayName || userDataService.user.username :
						contactFrom.name;
					contactFrom.archived_conversation = contactFrom.archived_conversation || [];
					body = $('<div/>').text(body).html();
					const m = {
						text: linkify(body, true),
						jid: from,
						name: name,
						pic: isMe ? null : contactFrom.pic,
						isMe: isMe,
						dt: dt
					};
					if (!contactFrom.dt || dt > contactFrom.dt) {
						contactFrom.dt = dt;
					}
					if (unseen && !isMe) {
						contactFrom.unreadCount++;
					}
					contactFrom.archived_conversation.push(m);

				} catch (err) {
					$log.error(err);
				}
				return true;
			};
			const handleRef = stropheConnectionService.parameters.connection.addHandler(handleMessage, null, 'message', "chat");
			const handleComplete = async (response) => {
				stropheConnectionService.parameters.connection.deleteHandler(handleRef);
				return true;
			};

			return new Promise((resolve, reject) => {
				stropheConnectionService.parameters.connection.mam.query(vm.username,
					{
						"start": startDate,
						"end": endDate,
						"queryid": queryId,
						"max": 250,
						onMessage: handleMessage,
						onComplete: (response) => resolve(handleComplete(response)),
						onError: (error) => {
							reject(error);
							stropheConnectionService.parameters.connection.deleteHandler(handleRef);
						}
					}

				);

			});
		}

		function onMessage(msg) {
			try {
				var from = $(msg).attr("from");
				var body = $(msg).children("body").text() || "";
				var state = $(msg).find("inactive,active,composing,paused,gone");
				var bareJid = Strophe.getBareJidFromJid(from);
				var msgType = $(msg).attr('type');
				var foundItem = findContact(bareJid);
				if (foundItem) {
					const isMe = bareJid === userDataService.user.emailAddress;
					var name = isMe ? userDataService.user.displayName || userDataService.user.username : foundItem.name || bareJid;
					if (bareJid === coreData.user.emailAddress) {
						name = coreData.user.displayName ? coreData.user.displayName : name;
					}

					if (msgType === 'chat' && body) {
						
						var html_body = $('html > body', msg);
						if (html_body.length > 0) {
							html_body = $sanitize($('<div>').append(html_body.contents()).html());
						} else {
							html_body = null;
						}

						var isplain = !html_body;
						if (!html_body) {
							html_body = $('<div/>').text(body).html();
						}

						if (!foundItem.convoAtBottom || !document.hasFocus())
							foundItem.unreadCount++;
						if (!foundItem.conversation)
							foundItem.conversation = [];
						var message = {
							text: linkify(html_body, isplain), 
							jid: bareJid, 
							name: name, 
							pic: null, 
							isMe: isMe, 
							dt: new Date()
						};
						foundItem.dt = message.dt;
						foundItem.conversation.push(message);

						$rootScope.$broadcast('xmpp.conversation-changed', { jid: bareJid, conversation: foundItem.conversation });

						recalculateUnread();

						// TODO: Check if this is already a visible page or not
						if (coreDataSettings.userSettings.notifyOnChatMessages && (!foundItem.convoAtBottom || !document.hasFocus())) {
							var convertedHtml = $("<div>" + html_body + "</div>").text();
							if (convertedHtml.toLowerCase().indexOf('http://') == 0 ||
								convertedHtml.toLowerCase().indexOf('https://') == 0)
								convertedHtml = $filter('translate')('LINK_RECEIVED');

							browserNotifications.show(name,
								{
									body: convertedHtml,
									tag: bareJid,
									icon: (stSiteRoot || '/') + 'interface/img/notifications/chat.png',
									notifyClick: function (e) {
										window.focus();
										$rootScope.$broadcast("xmpp.select-user", { jid: bareJid });
									}
								});
						}

					}

					if (state.length > 0) {
						var composing = $(msg).find("composing");
						foundItem.isTyping = composing.length > 0;
						$rootScope.$broadcast('xmpp.typing-changed', { jid: bareJid, contact: foundItem });
					}
				}
			} catch (ex) { $log.error(ex); }
			return true;
		}

		function onGroupMessage(iq) {
			return true;
		}

		// Private Functions
		function decodeMessage(msg) {
			var from = msg.getAttribute('from');
			var type = msg.getAttribute('type');
			var elems = msg.getElementsByTagName('body');

			if (type === 'chat' && elems.length > 0) {
				var body = elems[0];
				this.onMessage({
					from: from,
					text: Strophe.getText(body)
				});
			}
			return true;
		}

		function linkify(text, isplain) {
			var added = {};
			var textToAppend = "";
			var textToPrepend = "";
			var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
			var aRegex = /(<a[^>]*href=)(('([^']*)'|"([^"]*)"|([\S]*))[^>]*>(.*?)<\/a>)/g;

			var replaced = text;
			if (!isplain) {
				replaced = replaced.replace(aRegex, '<a target="blank_" href=$2');
			}

			replaced = replaced.replace(urlRegex, function (url) {

				if (added[url] === undefined) {
					if (/\.(gif|png|jpe?g)$/i.test(url)) {
						var parts = url.split('/d/');
						var newUrl = parts[0] + '/api/v1/filestorage/download-file-preview/' + parts[1];
						newUrl = newUrl.replace("https:", "http:");
						textToAppend += "<a href='" + url + "' target='_blank' class='chat-image'><img class='st-loading-image' src='" + newUrl + "'></a>";
					}

					var youTube1 = /youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9-_]+)/gi;
					var match = youTube1.exec(url);
					if (match) {
						textToAppend += "<a href='" + url + "' target='_blank' rel='noopener noreferrer' class='chat-image'><i class='toolsicon toolsicon-youtube sidelogo'></i><img src='http://img.youtube.com/vi/" +
							match[1]
							+ "/0.jpg'></a>";
					}

					var youTube2 = /youtu\.be\/([a-zA-Z0-9-_]+)/gi;
					var match = youTube2.exec(url);
					if (match) {
						textToAppend += "<a href='" + url + "' target='_blank' rel='noopener noreferrer' class='chat-image'><i class='toolsicon toolsicon-youtube sidelogo'></i><img src='http://img.youtube.com/vi/" +
							match[1]
							+ "/0.jpg'></a>";
					}

					var appearIn = /https:\/\/appear.in\/.+/gi;
					var match = appearIn.exec(url);
					if (match) {
						textToPrepend += $filter("translate")("CHAT_SECTION_VIDEO_CHAT_LINK") + "<br/>";
					}

					var appearIn = /https:\/\/meet.jit.si\/.+/gi;
					var match = appearIn.exec(url);
					if (match) {
						textToPrepend += $filter("translate")("CHAT_SECTION_VIDEO_CHAT_LINK") + "<br/>";
					}

					var etherpad = /https:\/\/etherpad.wikimedia.org\/p\/.+/gi;
					var match = etherpad.exec(url);
					if (match) {
						textToPrepend += $filter("translate")("CHAT_SECTION_DOCUMENT_SHARING_LINK") + "<br/>";
					}

					added[url] = true;
				}

				if (isplain)
					return '<a href="' + url + '" rel="noopener noreferrer" target="_blank">' + url + '</a>';
				else
					return url;
			});

			if (!isplain) {
				// TODO: Add target blank to any URLs found in non-plain parts
			}

			return textToPrepend + replaced + textToAppend;
		}

		function recalculateUnread() {
			var temp = 0;
			for (var cat in vm.contactCategories) {
				for (var contactIndex in vm.contactCategories[cat].contacts) {
					var contact = vm.contactCategories[cat].contacts[contactIndex];
					if (contact)
						temp += contact.unreadCount;
				}
			}
			if (vm.unread != temp) {
				vm.unread = temp;
				$rootScope.$broadcast('xmpp.property-changed', { unreadCount: temp });
			}
		}

		function storeUnreadCount(contact) {
			var counts = vm.parameters.unreadCounts;

			if (!counts[contact.jid]) counts[contact.jid] = {};
			counts[contact.jid].unread = contact.unreadCount;
			counts[contact.jid].time = moment();

			vm.parameters.unreadCounts = counts;

		}

		function loadUnreadCounts() {
			var counts = vm.parameters.unreadCounts;

			if (!vm.contactCategories || !vm.contactCategories[0]) {
				vm.contactCategories = [{ contacts: [] }];
			}

			for (var key in counts) {
				var foundItem = findContact(key);
				if (foundItem) foundItem.unreadCount = counts[key].unread;
				//else vm.contactCategories[0].contacts.push({ jid: key, unreadCount: counts[key].unread });
			}
		}

		function addContact(jid, displayName) {
			var iq = $iq({ type: "set" })
				.c("query", { xmlns: Strophe.NS.ROSTER })
				.c("item", { jid: jid, name: displayName });
			stropheConnectionService.parameters.connection.sendIQ(iq);
			stropheConnectionService.parameters.connection.send($pres({ to: jid, "type": "subscribe" }));
		}

		async function contactFromIqItem(iqItem) {
			var jid = $(iqItem).attr("jid");
			var bareJid = Strophe.getBareJidFromJid(jid);
			var displayJid = jid.split("@")[0] + "@" + coreData.user.domain;
			var name = $(iqItem).attr("name") || bareJid;
			var subscription = $(iqItem).attr("subscription");
			var group = $(iqItem).find("group").text() || "";

			await coreDataContacts.ensureSourcesLoadedPromise();
			await coreDataContacts.ensureContactsLoadedPromise();

			var contact = coreDataContacts.getContactByEmail(jid);
			var pic = null;
			if (contact && contact.image && !contact.image.indexOf('data=default') > -1) { pic = contact.image }
			if (contact && !name) {

				name = contact.displayName || contact.userName;
			}
			return {
				jid: jid,
				bareJid: bareJid,
				displayJid: displayJid,
				name: name,
				subscription: subscription,
				group: group,
				pic: pic
			};
		}

		function findContact(jid) {
			if (!jid) return null;
			jid = jid.toLowerCase();

			return vm.contactCategories
				.flatMap(category => category.contacts || [])
				.find(contact => contact && contact.jid === jid) || null;
		}

		function addRosterContact(contact) {
			if (!contact || !vm.contactCategories) return;

			let foundList = vm.contactCategories.find(category => category.name === contact.group);
			if (!foundList) {
				foundList = { name: contact.group, open: true, contacts: [] };
				vm.contactCategories.push(foundList);
			}

			const existingContact = foundList.contacts.find(c => c.jid === contact.bareJid);
			if (existingContact) {
				const composeContents = existingContact.composeContents;
				Object.assign(existingContact, {
					name: contact.name,
					jid: contact.bareJid,
					displayJid: contact.displayJid,
					username: contact.username,
					status: existingContact.status,
					unreadCount: 0,
					pic: contact.pic,
					dt: contact.ts
				});
				existingContact.composeContents = composeContents;
			} else {
				foundList.contacts.push({
					name: contact.name,
					jid: contact.bareJid,
					displayJid: contact.displayJid,
					username: contact.username,
					status: contact.group.endsWith("Aliases") ? "room" : "offline",
					unreadCount: 0,
					pic: contact.pic,
					dt: new Date("2001")
				});
			}
		}

		function removeRosterContact(contact) {
			if (!contact || !vm.contactCategories) return;
			for (var catIndex = 0; catIndex < vm.contactCategories.length; catIndex++) {
				var cat = vm.contactCategories[catIndex];
				for (var contactIndex = 0; contactIndex < cat.contacts.length; contactIndex++) {
					var c = cat.contacts[contactIndex];
					if (c.jid == contact.bareJid) {
						cat.contacts.splice(contactIndex, 1);
						contactIndex--;
					}
				}
			}
		}
	}

})();